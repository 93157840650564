import cx from 'classnames'

import { SanityImageFragment } from '@data/sanity/queries/types/image'

import LogoLink from './logo-link'
import { LayoutValue } from '@lib/image'

interface LogoProps {
  isInvertedLogo: boolean
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  logoImageLayout?: LayoutValue
  className?: string
}

const Logo = ({
  isInvertedLogo,
  logo,
  invertedLogo,
  logoImageLayout = 'fill',
  className,
}: LogoProps) => {
  const isInvertedLogoVisible = !!invertedLogo?.asset && isInvertedLogo

  return (
    <div className={cx('relative', className)}>
      {logo?.asset && (
        <LogoLink
          id="logo"
          image={logo}
          imageLayout={logoImageLayout}
          className={cx('transition-opacity', {
            'opacity-0': isInvertedLogoVisible,
          })}
        />
      )}

      {invertedLogo?.asset && (
        <LogoLink
          id="inverted-logo"
          image={invertedLogo}
          imageLayout={logoImageLayout}
          className={cx('transition-opacity absolute inset-0', {
            'opacity-0': !isInvertedLogoVisible,
          })}
        />
      )}
    </div>
  )
}

export default Logo
