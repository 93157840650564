import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import {
  SanityFooterBlock1,
  SanityFooterBlock2,
  SanityFooterBlock3,
  SanityFooterBlock4,
} from '@data/sanity/queries/types/site'
import { serializers } from '@lib/serializers'

import Newsletter from '@blocks/newsletter'

interface FooterBlockProps {
  block:
    | SanityFooterBlock1
    | SanityFooterBlock2
    | SanityFooterBlock3
    | SanityFooterBlock4
  className?: string
}

const FooterBlock = ({ block, className }: FooterBlockProps) => {
  const id = `footer-${block.title?.toLowerCase() ?? 'block'}`

  return (
    <div
      className={cx(
        'flex flex-col text-left',
        // {
        //   'lg:order-first': 'social' in block,
        //   'lg:order-last': 'newsletter' in block,
        // },
        className
      )}
    >
      <h3 className="text-lg mb-4">{block.title}</h3>

      {'description' in block && block.description && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mb-5"
          blocks={block.description}
          serializers={serializers}
        />
      )}

      {'newsletter' in block && block.newsletter && (
        <Newsletter
          id={id}
          service={block.newsletter.service}
          hubSpotFormId={block.newsletter.hubSpotFormId}
          klaviyoListID={block.newsletter.klaviyoListID}
          submit={block.newsletter.submit}
          successMsg={block.newsletter.successMsg}
          errorMsg={block.newsletter.errorMsg}
          terms={block.newsletter.terms}
          isInvertedColors
          isInlineButton
        />
      )}

      {'social' in block && block.social && (
        <ul className="flex flex-col lg:mb-8 px-0">
          {block.social.map((link) => (
            <li key={link._key}>
              <a
                id={`footer-social-link-${link.name.toLowerCase()}`}
                className="hover:opacity-60 transition-opacity duration-200 inline-block text-base text-left"
                key={link._key}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default FooterBlock
