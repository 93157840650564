import { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import axios from 'axios'
import useSWR from 'swr'

import {
  SanityProductGalleryPhoto,
  SanityProductOption,
  SanityProductOptionName,
  SanityProductVariantFragment,
  SanityProductFragment,
  SanityProductOptionSetting,
  SanityProductVariantOption,
  SanityProductPage,
  SanityProductType,
} from '@data/sanity/queries/types/product'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { SanityProductCatalogueQuery } from '@data/sanity/queries/types/product'
import { ProductInventory } from './shopify/product'
import {
  hasObject,
  parseOptionalParameter,
  usePrevious,
  useUrlParameters,
} from './helpers'
import { Locale } from './language'
import { SiteContext } from './site'
import { ShopContext } from './shop'
import { UserProduct } from './user'
import { useUser } from './auth'

export interface Filter {
  name: string
  values: string[]
}

export interface FilterValue {
  name: string
  value: string
}

/**
 * Gets product gallery photos for selected variant.
 */
export const getProductGalleryPhotos = (
  photosets: SanityProductGalleryPhoto[],
  variant: SanityProductVariantFragment
): SanityImageFragment[] => {
  const variantPhotoset = photosets.find(({ forOption }) => {
    const option = forOption
      ? { name: forOption.split(':')[0], value: forOption.split(':')[1] }
      : {}

    return option.value && hasObject(variant.options, option)
  })

  if (variantPhotoset?.photos?.length) {
    return variantPhotoset.photos
  }

  return photosets.find(({ forOption }) => !forOption)?.photos ?? []
}

/**
 * Gets a list of user's digital products from product catalogue filtered by IDs.
 */
export const getUserDigitalProducts = (
  productCatalogue: SanityProductCatalogueQuery,
  productIds: number[],
  activeSubscriptionProductIds: number[]
): UserProduct[] => {
  const digitalProducts: UserProduct[] = []

  productCatalogue
    .filter(({ id }) => productIds.includes(id))
    .forEach((product) => {
      switch (product.type) {
        case 'digital': {
          return digitalProducts.push({
            id: product.id,
            title: product.title,
            slug: product.slug,
          })
        }

        case 'subscription': {
          return product.subscriptionProductIds
            ?.filter((productId) =>
              activeSubscriptionProductIds.includes(productId)
            )
            ?.forEach((productId) =>
              productCatalogue
                .filter(
                  ({ id, type }) => id === productId && type === 'digital'
                )
                .forEach((childProduct) =>
                  digitalProducts.push({
                    id: childProduct.id,
                    title: childProduct.title,
                    slug: childProduct.slug,
                  })
                )
            )
        }
      }
    })

  return digitalProducts
}

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Gets product option label.
 */
export const getOptionLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Get the merge result of product inventory and product.
 */
export const getProductWithInventory = (
  product: SanityProductFragment,
  productInventory: ProductInventory
): SanityProductFragment => ({
  ...product,
  inStock: productInventory.inStock,
  lowStock: productInventory.lowStock,
  variants: [
    ...(product.variants?.map((variant) => ({
      ...variant,
      ...(productInventory.variants.find(({ id }) => id === variant.id) ?? {}),
    })) ?? []),
  ],
})

/**
 * Digital product content type hook.
 */
export const useDigitalProductContent = (
  product: SanityProductFragment
): [boolean, boolean] => {
  const { productCatalogue } = useContext(ShopContext)
  const { user } = useUser()

  const productType = product.type
    ? (product.type as SanityProductType)
    : SanityProductType.PHYSICAL

  const [userDigitalProducts, setUserDigitalProducts] = useState<
    UserProduct[] | null
  >(null)
  const [showPrivateContent, setShowPrivateContent] = useState(false)
  const [isLoaded, setIsLoaded] = useState(
    productType !== SanityProductType.DIGITAL
  )

  // Load digital products owned by current user, if this is a digital product
  useEffect(() => {
    if (productType !== SanityProductType.DIGITAL || !user) {
      return
    }

    if (!user.isLoggedIn) {
      setUserDigitalProducts([])
      return
    }

    const userProductIds = user.ownedProducts?.map(({ id }) => id) ?? []
    const userSubscriptionProductIds = user.activeSubscriptionProductIds ?? []

    setUserDigitalProducts(
      getUserDigitalProducts(
        productCatalogue,
        userProductIds,
        userSubscriptionProductIds
      )
    )
  }, [productCatalogue, productType, user])

  // Detect public or private content, if this is a digital product
  useEffect(() => {
    if (
      productType !== SanityProductType.DIGITAL ||
      userDigitalProducts === null
    ) {
      return
    }

    setShowPrivateContent(
      userDigitalProducts.some(({ id }) => id === product.id)
    )

    setIsLoaded(true)
  }, [product.id, productType, userDigitalProducts])

  return [showPrivateContent, isLoaded]
}

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[]
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption)
  )

  return variant ?? null
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  page: SanityProductPage,
  locale: Locale
): SanityProductFragment => {
  const [product, setProduct] = useState(page.product)

  // Check if product inventory is still correct
  const { data: productInventory } = useSWR<ProductInventory>(
    ['/api/shopify/product-inventory', page.product.id, locale],
    async (url: string, id: number, localeHeader: Locale) => {
      const response = await axios.get<ProductInventory>(url, {
        params: { id },
        headers: { 'X-Locale': localeHeader },
      })

      return response.data
    },
    { errorRetryCount: 3 }
  )

  // Rehydrate product after inventory is fetched
  useEffect(() => {
    if (!page.product || !productInventory) {
      return
    }

    setProduct((currentProduct) =>
      getProductWithInventory(currentProduct, productInventory)
    )
  }, [page.product, productInventory])

  return product
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (
  product: SanityProductFragment
): [SanityProductVariantFragment | undefined, (variantId: number) => void] => {
  const { isPageTransition } = useContext(SiteContext)

  const defaultOption = getDefaultOption(
    product.options,
    product.optionSettings ?? []
  )
  const firstVariant = product.variants?.[0] ?? null
  const defaultVariant =
    getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
    firstVariant
  const defaultVariantId = defaultVariant?.id ?? null

  // Manage URL parameters
  const [currentParameters, setCurrentParameters] = useUrlParameters([
    { name: 'variant', value: defaultVariantId ? `${defaultVariantId}` : null },
  ])
  const previousParameters = usePrevious(currentParameters)
  const activeParameters =
    isPageTransition && previousParameters
      ? previousParameters
      : currentParameters

  // Find active variant ID
  const parameterVariant = activeParameters.find(
    ({ name }) => name === 'variant'
  )
  const parameterVariantValue = parseOptionalParameter<string>(
    parameterVariant?.value
  )
  const parameterVariantId = parameterVariantValue
    ? Number(parameterVariantValue)
    : null
  const variantIds = useMemo(
    () => product.variants?.map(({ id }) => id) ?? [],
    [product.variants]
  )
  const matchingVariant = variantIds.some((id) => id == parameterVariantId)
  const activeVariantId = matchingVariant
    ? parameterVariantId
    : defaultVariantId

  const activeVariant = useMemo(
    () => product.variants?.find(({ id }) => id === activeVariantId),
    [product.variants, activeVariantId]
  )

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(({ name }) => name !== 'variant'),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds]
  )

  return [activeVariant, updateProductPageUrl]
}
