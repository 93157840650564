import { AnimatePresence, m } from 'framer-motion'

import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { fadeAnimation } from '@lib/animate'

import PhotoCarousel from '@components/photo-carousel'
import Photo from '@components/photo'

interface GalleryProps {
  photos: SanityImageFragment[]
  hasArrows?: boolean
  hasDots?: boolean
  hasDrag?: boolean
  hasCounter?: boolean
  preloadFirstPhoto?: boolean
}

const Gallery = ({
  photos,
  hasArrows = false,
  hasDots = false,
  hasDrag = false,
  hasCounter = false,
  preloadFirstPhoto = false,
}: GalleryProps) => {
  if (photos.length === 0) {
    return null
  }

  // Update ID to trigger transition animation
  const photosetId = photos.map(({ id }) => id).join('')

  return (
    <AnimatePresence exitBeforeEnter>
      <m.div
        key={photosetId}
        initial="hide"
        animate="show"
        exit="hide"
        variants={fadeAnimation}
      >
        <PhotoCarousel
          id={photosetId}
          hasArrows={hasArrows}
          hasDots={hasDots}
          hasCounter={hasCounter}
          hasDrag={hasDrag}
          cornerControls={true}
        >
          {photos.map((photo, index) => (
            <Photo
              key={photo.id}
              image={photo}
              priority={preloadFirstPhoto && index === 0}
            />
          ))}
        </PhotoCarousel>
      </m.div>
    </AnimatePresence>
  )
}

export default Gallery
