import cx from 'classnames'

import { SanityVimeoVideoMedia } from '@data/sanity/queries/types/video'
import { getVimeoVideoId } from '@lib/video'
import { SanityBorderRadius } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/image'

const DEFAULT_VIDEO_ASPECT = (9 / 16) * 100

interface VimeoVideoProps {
  video: SanityVimeoVideoMedia
  autoplay?: boolean
  borderRadius?: SanityBorderRadius
}

const VimeoVideo = ({ video, autoplay, borderRadius }: VimeoVideoProps) => {
  if (!video?.link) {
    return null
  }

  // Get video ID
  const videoId = getVimeoVideoId(video.link)

  const aspect =
    video.height && video.width
      ? (video.height / video.width) * 100
      : DEFAULT_VIDEO_ASPECT

  return (
    <div
      className={cx(
        'relative overflow-hidden',
        borderRadius ? borderRadiusClassMap[borderRadius] : ''
      )}
      style={{ paddingTop: `${aspect}%` }}
    >
      <div className="absolute inset-0">
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?autoplay=${autoplay}`}
          title={video.name}
          className="absolute top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2 w-full h-full"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
    </div>
  )
}

export default VimeoVideo
