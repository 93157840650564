import { useIntersectionObserver } from '@asyarb/use-intersection-observer'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

import {
  SanityMuxVideo,
  SanityVideoAspectRatio,
  SanityVideoAspectRatioValue,
} from '@data/sanity/queries/types/video'
import {
  aspectRatioPaddingClasses,
  getMuxVideoAspectRatio,
  MuxPlayerProps,
} from '@lib/video'
import { SanityBorderRadius } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/image'

const MuxPlayer = dynamic(() => import('./mux-player'))

type MuxVideoProps = Omit<MuxPlayerProps, 'assetDocument'> & {
  video: SanityMuxVideo
  aspectRatio?: SanityVideoAspectRatio
  borderRadius?: SanityBorderRadius
}

const getAspectRatioPaddingClass = (
  aspectRatio?: SanityVideoAspectRatioValue,
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl'
) =>
  aspectRatio
    ? `${breakpoint ? `${breakpoint}:` : ''}${
        aspectRatioPaddingClasses[aspectRatio]
      }`
    : ''

const MuxVideo = ({
  video,
  autoplay,
  showControls,
  muted,
  loop,
  aspectRatio,
  borderRadius,
}: MuxVideoProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isInView = useIntersectionObserver({
    ref: containerRef,
    options: {
      triggerOnce: true,
      threshold: 0.1,
    },
  })

  const videoAspectRatio = getMuxVideoAspectRatio(video)

  return (
    <div
      ref={containerRef}
      className={cx(
        'relative w-full z-0 h-full overflow-hidden',
        borderRadius ? borderRadiusClassMap[borderRadius] : '',
        aspectRatio?.custom && [
          getAspectRatioPaddingClass(
            aspectRatio.base ?? SanityVideoAspectRatioValue.ASPECT_9_16
          ),
          getAspectRatioPaddingClass(
            aspectRatio.sm ?? SanityVideoAspectRatioValue.ASPECT_16_9,
            'sm'
          ),
          getAspectRatioPaddingClass(aspectRatio.md, 'md'),
          getAspectRatioPaddingClass(aspectRatio.lg, 'lg'),
          getAspectRatioPaddingClass(aspectRatio.xl, 'xl'),
        ]
      )}
      style={{
        ...(!aspectRatio?.custom && {
          paddingTop: `${100 / videoAspectRatio}%`,
        }),
      }}
    >
      {isInView && (
        <MuxPlayer
          assetDocument={video.asset}
          showControls={showControls}
          autoplay={autoplay}
          muted={muted}
          loop={loop}
          playsInline={autoplay && !showControls}
          videoStyle={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  )
}

export default MuxVideo
