import { useRef, useContext, useEffect, useState, RefObject } from 'react'
import { m } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import { useIntersectionObserver } from '@asyarb/use-intersection-observer'
import { useRect } from '@reach/rect'
import cx from 'classnames'

import { SanityColor } from '@data/sanity/queries/types/modules'
import { SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { LayoutContext } from '@lib/layout-context'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import PromoBar from '../promo-bar'
import Logo from './logo'
import Icon from '@components/icon'

export interface HeaderSizeValues {
  height: number
  bottom: number
}

const textColors: Record<SanityColor, string> = {
  [SanityColor.WHITE]: 'text-header-text',
  [SanityColor.GRAY]: 'text-header-text',
  [SanityColor.GREEN_LIGHT]: 'text-black',
  [SanityColor.GREEN]: 'text-white',
  [SanityColor.GREEN_ELECTRIC]: 'text-white',
  [SanityColor.GREEN_DARK]: 'text-white',
  [SanityColor.PINK_FADED]: 'text-black',
  [SanityColor.PINK]: 'text-white',
  [SanityColor.PINK_HOT]: 'text-white',
  [SanityColor.BLUE]: 'text-white',
  [SanityColor.RED]: 'text-white',
  [SanityColor.YELLOW]: 'text-black',
}

interface HeaderProps extends SanityHeaderFragment {
  topObserverRef: RefObject<Element>
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  onResize?: (newValues: HeaderSizeValues) => void
}

const Header = ({
  promo,
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  logo,
  invertedLogo,
  topObserverRef,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, mobileMenu, toggleMegaNavigation, toggleMobileMenu } =
    useContext(SiteContext)
  const { isHeaderTransparent, headerBackgroundColor } =
    useContext(LayoutContext)

  const [isObserverVisible, setIsObserverVisible] = useState(true)
  const isInView = useIntersectionObserver({
    ref: topObserverRef,
    options: {
      triggerOnce: false,
      threshold: 1,
    },
  })
  const headerRef = useRef<HTMLDivElement>(null)
  const headerRect = useRect(headerRef)

  useEffect(
    () => setIsObserverVisible(isInView ?? isHeaderTransparent),
    [isInView, isHeaderTransparent]
  )

  useEffect(() => {
    if (headerRect && onResize) {
      onResize({
        height: headerRect.height,
        bottom: headerRect.top + headerRect.height,
      })
    }
  }, [headerRect, , onResize])

  const logoSizeClassnames = 'h-[60px] w-[60px] md:h-[100px] md:w-[100px]'

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      {promo && (
        <PromoBar
          enabled={promo.enabled}
          display={promo.display}
          text={promo.text}
          link={promo.link}
        />
      )}

      <header
        className={cx(
          'sticky top-0 inset-x-0 z-50',
          {
            'h-0': isHeaderTransparent,
          },
          headerBackgroundColor && isObserverVisible && !megaNavigation.isOpen
            ? textColors[headerBackgroundColor]
            : 'text-header-text'
        )}
      >
        <div ref={headerRef} className="relative">
          <div
            className={cx('relative z-30 py-3 transition-colors duration-300', {
              'bg-transparent': isHeaderTransparent && isObserverVisible,
              'bg-header-bg':
                (!isHeaderTransparent && !headerBackgroundColor) ||
                megaNavigation.isOpen,
              [`bg-${headerBackgroundColor}`]:
                !isHeaderTransparent &&
                headerBackgroundColor &&
                !megaNavigation.isOpen,
              'bg-header-bg bg-opacity-95': !isObserverVisible,
              'text-white delay-75':
                isHeaderTransparent &&
                !megaNavigation?.isOpen &&
                isObserverVisible,
            })}
          >
            <div className="px-4 lg:container flex flex-row justify-between items-center w-full relative z-30 min-h-[50px]">
              {/* Logo */}
              <Logo
                className={cx(
                  'transition-all duration-300',
                  logoSizeClassnames,
                  {
                    '!h-[60px] !w-[60px]': !isObserverVisible,
                  }
                )}
                logo={logo}
                invertedLogo={invertedLogo}
                isInvertedLogo={
                  (isHeaderTransparent ||
                    (!!headerBackgroundColor &&
                      headerBackgroundColor !== SanityColor.WHITE &&
                      headerBackgroundColor !== SanityColor.GRAY)) &&
                  isObserverVisible &&
                  !megaNavigation.isOpen
                }
              />

              {/* Mobile Header Menu */}
              <nav
                id="mobile-nav"
                className={cx('lg:hidden flex justify-end items-center')}
              >
                <FocusTrap active={mobileMenu.isOpen}>
                  <div className="flex items-center">
                    {/* Menu toggle */}
                    <button
                      onClick={() => toggleMobileMenu(!mobileMenu.isOpen)}
                      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
                      aria-controls="mobile-nav"
                      aria-label={strings.mobileMenuLabel}
                      className={cx(
                        'lg:hidden appearance-none no-underline cursor-pointer font-inherit text-3xl'
                      )}
                    >
                      <Icon id="header-open-menu-icon" name="Bars" />
                    </button>

                    <m.div
                      initial="hide"
                      animate={mobileMenu.isOpen ? 'show' : 'hide'}
                      variants={{
                        show: {
                          x: '0%',
                        },
                        hide: {
                          x: '-100%',
                        },
                      }}
                      transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
                      className="fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-green-dark text-white max-w-[500px] px-4"
                    >
                      <div className="flex justify-between py-3 md:py-5">
                        <Logo
                          logo={invertedLogo}
                          isInvertedLogo
                          className={logoSizeClassnames}
                        />

                        <button
                          onClick={() => toggleMobileMenu(false)}
                          className="text-4xl"
                        >
                          <Icon id="header-close-menu-icon" name="X" />
                        </button>
                      </div>

                      <div className="py-10 flex-1 flex flex-col overflow-y-scroll no-scrollbar">
                        {!!menuMobilePrimary?.items && (
                          <Menu
                            id={menuMobilePrimary.slug}
                            items={menuMobilePrimary.items}
                            onClick={() => toggleMobileMenu(false)}
                            isHeaderMobilePrimaryMenu
                          />
                        )}

                        {!!menuMobileSecondary?.items && (
                          <Menu
                            id={menuMobileSecondary.slug}
                            items={menuMobileSecondary.items}
                            onClick={() => toggleMobileMenu(false)}
                            isHeaderMobileSecondaryMenu
                            className="pt-6"
                          />
                        )}
                      </div>
                    </m.div>

                    <div
                      className={cx(
                        'fixed inset-0 z-40 bg-backdrop bg-opacity-40 pointer-events-none transition-opacity duration-150 ease-linear',
                        {
                          'opacity-0': !mobileMenu.isOpen,
                          'pointer-events-auto backdrop-filter-blur-[6px] opacity-100':
                            mobileMenu.isOpen,
                        }
                      )}
                      onClick={() => toggleMobileMenu(false)}
                      onKeyPress={() => null}
                      role="presentation"
                    />
                  </div>
                </FocusTrap>
              </nav>

              {/* Desktop Header Menu */}
              <nav className="hidden lg:flex items-center justify-center py-2 absolute left-0 right-0">
                {!!menuDesktopLeft?.items && (
                  <Menu
                    id={menuDesktopLeft.slug}
                    items={menuDesktopLeft.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>

              <nav className="hidden lg:flex items-center relative z-1">
                {!!menuDesktopRight?.items && (
                  <Menu
                    id={menuDesktopRight.slug}
                    items={menuDesktopRight.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>
            </div>
          </div>

          <MegaNavigation
            items={[
              ...(menuDesktopLeft?.items ?? []),
              ...(menuDesktopRight?.items ?? []),
            ]}
            headerHeight={headerRect?.height ?? 0}
          />
        </div>
      </header>
    </>
  )
}

export default Header
