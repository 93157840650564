import { SanityFooterFragment } from '@data/sanity/queries/types/site'
import cx from 'classnames'

import FooterBlock from './block'
import FooterCopyright from './copyright'

type FooterProps = SanityFooterFragment

const Footer = ({ blocks, copyright, paymentMethods }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-green-dark text-white pt-20 pb-14"
    role="contentinfo"
  >
    <div className="container">
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 mb-8 gap-y-10 sm:gap-x-20 lg:gap-x-24">
        {blocks.map((block, index) => (
          <FooterBlock key={index} block={block} />
        ))}
      </div>

      <FooterCopyright copyright={copyright} paymentMethods={paymentMethods} />
    </div>
  </footer>
)

export default Footer
