import cx from 'classnames'

import { SanityLinkFragment } from '@data/sanity/queries/types/link'
import { SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { SanityLink } from '@data/sanity/queries/types/content'
import { getPageUrl, PageType } from '@lib/routes'
import { useUser } from '@lib/auth'

import AccountButton from '@components/buttons/account-button'
import LanguageSwitch from '@components/language-switch'
import CustomLink from '@components/link'
import Dropdown from './dropdown'
import DropdownButton from './dropdown-button'

export type ItemType = SanityLinkFragment | SanityMenuFeaturedLinkFragment

interface MenuItemProps {
  menuId?: string
  item: ItemType
  hasFocus: boolean
  useMegaNav: boolean
  onClick?: () => void
  isFooterMenuItem?: boolean
  isHeaderDesktopMenuItem?: boolean
  isHeaderMobilePrimaryMenuItem?: boolean
  isHeaderMobileSecondaryMenuItem?: boolean
  isMegaNavMenuItem?: boolean
}

const MenuItem = ({
  menuId,
  item,
  hasFocus,
  useMegaNav,
  onClick,
  isFooterMenuItem,
  isHeaderDesktopMenuItem,
  isHeaderMobilePrimaryMenuItem,
  isHeaderMobileSecondaryMenuItem,
  isMegaNavMenuItem,
}: MenuItemProps) => {
  const { user } = useUser()

  const title = 'title' in item && item.title ? item.title : ''
  const isButton = 'isButton' in item && item.isButton ? item.isButton : false

  const itemClassNames = cx(
    !isButton
      ? {
          'hover:opacity-60 transition-opacity duration-200': true,
          'inline-block text-base text-left': isFooterMenuItem,
          'block font-semibold text-lg p-2': isHeaderDesktopMenuItem,
          'block p-0 text-3xl font-semibold text-left':
            isHeaderMobilePrimaryMenuItem,
          'block p-0 text-xl font-semibold text-left':
            isHeaderMobileSecondaryMenuItem,
          'inline-block text-4xl leading-normal': isMegaNavMenuItem,
        }
      : {}
  )

  // Language switch
  if (item._type === 'navLanguageSwitch') {
    return (
      <LanguageSwitch
        id={item._key}
        buttonClassName={itemClassNames}
        hasCurrency={item.displayCurrency}
      />
    )
  }

  // Account button
  if (item._type === 'navAccountButton') {
    const accountUrl = getPageUrl(
      user?.isLoggedIn ? PageType.ACCOUNT_PAGE : PageType.LOGIN_PAGE
    )

    return (
      <AccountButton
        id={`account-button-${item._key}`}
        className={cx(itemClassNames, { 'p-0': !item.text })}
        showIcon={item.displayIcon}
        href={accountUrl}
      >
        {item.text}
      </AccountButton>
    )
  }

  // Dropdown list
  if (item._type === 'navDropdown') {
    const dropdownItems = (item as SanityMenuFeaturedLinkFragment).dropdownItems

    if (useMegaNav) {
      return (
        <DropdownButton
          id={item._key}
          title={title}
          className={itemClassNames}
        />
      )
    }

    if (!dropdownItems) {
      return null
    }

    return (
      <Dropdown
        id={item._key}
        title={title}
        items={dropdownItems}
        onClick={onClick}
        listItemClassName={cx({
          'mx-2 my-0': isHeaderDesktopMenuItem,
          'leading-snug':
            isHeaderMobilePrimaryMenuItem || isHeaderMobileSecondaryMenuItem,
          'text-2xl': isHeaderMobilePrimaryMenuItem,
          'text-lg': isHeaderMobileSecondaryMenuItem,
        })}
        buttonClassName={itemClassNames}
      />
    )
  }

  // Single link
  return (
    <CustomLink
      link={
        {
          ...item,
          ...(menuId && {
            id: `${menuId}-${item.title.toLowerCase().replace(/ /g, '-')}`,
          }),
        } as SanityLink
      }
      onClick={onClick}
      tabIndex={!hasFocus ? -1 : 0}
      className={itemClassNames}
      showCollectionCount={isMegaNavMenuItem}
    >
      {title}
    </CustomLink>
  )
}

export default MenuItem
